import Coordonnees from "./Coordonnees";
import InformationsPersonnelles from "./InformationsPersonnelles";
import InformationsProfessionnelles from "./InformationsProfessionnelles";
import Inscription from "./Inscription";

export default class FormulaireAdhesion {
  informationsPersonnelles = new InformationsPersonnelles()
  coordonnees = new Coordonnees()
  informationsProfessionnelles = new InformationsProfessionnelles()
  inscription = new Inscription()
}