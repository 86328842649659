import { Api } from "../api.js"
const catalogue = (code) => {
  return Api.get(`/catalogues/${code}?token=${process.env.VUE_APP_TOKEN}`)
}

export default {
  salutations: () => {
    return catalogue(204)
  },
  sectionsRegionales: () => {
    return catalogue(247)
  },
  provinces: () => {
    return catalogue(307)
  },
  villes: () => {
    return catalogue(310)
  },
  etudes: () => {
    return catalogue(253)
  },
  statuts: () => {
    return catalogue(255)
  },
  milieux: () => {
    return catalogue(256)
  },
  champsActivite: () => {
    return catalogue(257)
  },
  presences: () => {
    return catalogue(12931)
  },
  restrictionsAlimentaires: () => {
    return catalogue(235)
  },
}