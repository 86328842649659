import axios from 'axios'

const Api =
    axios.create({
        withCredentials: false,
        baseURL: process.env.VUE_APP_BASE_API_URL,
        headers: {
            Accept: 'application/json',
        }
    });

const CheckIfMembre = (formulaire) => {
    return Api.post(`/adhesion/check-if-membre`, {
        email: formulaire.informationsPersonnelles.courriel,
        nom: formulaire.informationsPersonnelles.nom,
        prenom: formulaire.informationsPersonnelles.prenom,
    })
}

const TarifAdhesion = (formulaire) => {
    return Api.post(`/adhesion/tarif`, {
        membre: {
            email: formulaire.informationsPersonnelles.courriel,
            nom: formulaire.informationsPersonnelles.nom,
            prenom: formulaire.informationsPersonnelles.prenom,
        },
        informationsProfessionnelles: {
            ...formulaire.informationsProfessionnelles
        }
    })
}

const GetEvenement = (evenementSlug) => {
    return Api.get(`/evenements?token=${process.env.VUE_APP_TOKEN}&evenementSlug=${evenementSlug}`)
}

const AbonnementInfolettre = (formulaire) => {
    return Api.post(`/abonnement-infolettre`, {
        token: process.env.VUE_APP_TOKEN,
        ...formulaire.informationsPersonnelles
    })
}

const CreateAdhesion = (formulaire) => {
    return Api.post(`/adhesion`, {
        token: process.env.VUE_APP_TOKEN,
        ...formulaire
    })
}

const findTarifSelectionne = (activite, tarifsSelectionnes) => {
    let typeTarifSelctionne = tarifsSelectionnes.find(t => t && t.startsWith(`${activite.produitId}|`))
    if (typeTarifSelctionne) {
        let found = activite.tarifs.find(
            (t) => t.typeTarifId == typeTarifSelctionne.split('|')[1]
        )
        return found ?? activite.tarifs[0]
    }
    return activite.tarifs[0]
}

const InscriptionCongres = (formulaire, evenementSlug, principales, activitesSelectionnes) => {

    let activitePrincipale;
    let activitesSecondaires;
    if (formulaire.inscription.inscriptionComplete === 'oui') {
        activitePrincipale = principales[0]
        activitesSecondaires = activitesSelectionnes
    } else {
        activitePrincipale = activitesSelectionnes[0]
        activitesSecondaires = activitesSelectionnes.slice(1)
    }

    return Api.post(`/achat-billet/congres`, {
        token: process.env.VUE_APP_TOKEN,
        informationsPersonnelles: {
            ...formulaire.informationsPersonnelles
        },
        coordonnees: {
            ...formulaire.coordonnees
        },
        informationsProfessionnelles: {
            ...formulaire.informationsProfessionnelles
        },
        inscription: {
            evenementSlug: evenementSlug,
            activitePrincipale: {
                activiteId: activitePrincipale.id,
                produitId: activitePrincipale.produitId,
                typeTarifId: activitePrincipale.afficherTousLesTarifs ? findTarifSelectionne(activitePrincipale, formulaire.inscription.tarifsSelectionnes).typeTarifId : activitePrincipale.tarif.typeTarifId,
                typeEvenementId: activitePrincipale.categorieProduit
            },
            autresActivitesSelectionnees: activitesSecondaires.map(a => {
                return {
                    produitId: a.produitId,
                    typeTarifId: a.afficherTousLesTarifs ? findTarifSelectionne(a, formulaire.inscription.tarifsSelectionnes).typeTarifId : a.tarif.typeTarifId,
                    activiteId: a.id
                }
            }),
        }
    })
}

const InscriptionActiviteRegionale = (formulaire, evenementSlug, activitePrincipale) => {

    let inscritPrincipal = formulaire.inscription.invites[0]
    return Api.post(`/achat-billet/activite-regionale`, {
        token: process.env.VUE_APP_TOKEN,
        informationsPersonnelles: {
            ...formulaire.informationsPersonnelles
        },
        coordonnees: {
            ...formulaire.coordonnees
        },
        informationsProfessionnelles: {
            ...formulaire.informationsProfessionnelles
        },
        inscription: {
            evenementSlug: evenementSlug,
            activitePrincipale: {
                activiteId: activitePrincipale.id,
                produitId: activitePrincipale.produitId,
                typeTarifId: inscritPrincipal.tarif.typeTarifId,
                typeEvenementId: activitePrincipale.categorieProduit
            },
            invites: [...formulaire.inscription.invites
                .map(i => {
                    return {
                        nom: i.nom,
                        prenom: i.prenom,
                        courriel: i.courriel,
                        tarifId: i.tarif.tarifId,
                        typeTarif: i.tarif.typeTarifId,
                        produitId: activitePrincipale.produitId,
                        restrictionsAlimentaires: i.restrictionsAlimentaires,
                        autresRestrictionsAlimentaires: i.autresRestrictionsAlimentaires
                    }
                })
            ]
        }
    })
}

export { Api, CheckIfMembre, GetEvenement, CreateAdhesion, TarifAdhesion, AbonnementInfolettre, InscriptionCongres, InscriptionActiviteRegionale }