<template>
  <div>
    <StepHeadline>Informations professionnelles</StepHeadline>
    <div class="formulaire-inner">
      <v-form v-model="valid" ref="mainForm">
        <h4 class="mb-2">Études universitaires<star /></h4>
        <v-select
          outlined
          v-model="informationsProfessionnelles.etudesUniversitaires"
          :items="catalogues.etudes"
          item-text="libelleFR"
          item-value="id"
          :rules="required"
          @change="updateTarif"
        >
        </v-select>

        <h4>Statut<star /></h4>
        <v-radio-group v-model="informationsProfessionnelles.statut" :rules="required" @change="updateTarif">
          <v-radio v-for="statut in catalogues.statuts" :key="statut.id" :value="statut.id" :label="statut.libelleFR" class="mb-3"> </v-radio>
        </v-radio-group>

        <h4 class="mb-2">Milieux d'action<star /></h4>
        <v-select
          outlined
          v-model="informationsProfessionnelles.milieuxAction"
          multiple
          :items="catalogues.milieux"
          item-text="libelleFR"
          item-value="id"
          :rules="required"
        >
        </v-select>

        <h4 class="mb-2">Champs d'activité ou domaines d'intérêt</h4>
        <v-select
          outlined
          v-model="informationsProfessionnelles.champsActivite"
          multiple
          :items="catalogues.champsActivite"
          item-text="libelleFR"
          item-value="id"
        >
        </v-select>
      </v-form>
    </div>
  </div>
</template>

<script>
import StepHeadline from "@/components/StepHeadline"
import FormBase from "@/mixins/FormBase"
import Rules from "@/mixins/Rules"
import { mapGetters } from "vuex"
import { bus } from "@/plugins/bus"

export default {
  mixins: [FormBase, Rules],
  components: {
    StepHeadline
  },

  props: {
    formulaire: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["catalogues"]),
    informationsProfessionnelles() {
      return this.formulaire.informationsProfessionnelles
    }
  },
  methods: {
    updateTarif() {
      bus.$emit("updateTarif")
    }
  }
}
</script>

<style>
</style>