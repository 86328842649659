<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <v-radio-group row v-model="adresse.typeAdresse" class="mb-4" :rules="required" :disabled="typeAdresseDisabled">
        <v-radio label="Adresse personnelle" value="perso"></v-radio>
        <v-radio label="Adresse professionnelle" value="pro"></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12" class="py-0" v-if="adresse.typeAdresse === 'pro'">
      <v-text-field outlined v-model="adresse.nomOrganisme" :rules="required">
        <template v-slot:label>Nom de l'organisme<star /></template>
      </v-text-field>
    </v-col>
    <v-col cols="12" class="py-0" v-if="adresse.typeAdresse === 'pro'">
      <v-text-field outlined v-model="adresse.titreExact" label="Titre"> </v-text-field>
    </v-col>
    <v-col cols="12" md="8" class="py-0">
      <v-text-field outlined v-model="adresse.adresse" :rules="required">
        <template v-slot:label>Adresse<star /></template>
      </v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
      <v-text-field outlined v-model="adresse.codePostal" v-facade="'A#A #A#'" :rules="codePostalRules" validate-on-blur>
        <template v-slot:label>Code postal<star /></template>
      </v-text-field>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
      <v-autocomplete
        outlined
        v-model="adresse.ville"
        :items="catalogues.villes"
        item-text="libelleFR"
        item-value="id"
        auto-select-first
        :rules="required"
        :autocomplete="false"
      >
        <template v-slot:label>Ville<star /></template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
      <v-autocomplete
        outlined
        v-model="adresse.province"
        :items="catalogues.provinces"
        item-text="libelleFR"
        item-value="id"
        auto-select-first
        :rules="required"
      >
        <template v-slot:label>Province<star /></template>
      </v-autocomplete>
    </v-col>
    <template v-if="!hidePhoneNumbers">
      <v-col cols="12" md="8" class="py-0">
        <v-text-field outlined v-model="adresse.telephonePrincipal" v-facade="'###-###-####'" :rules="phoneRules" validate-on-blur>
          <template v-slot:label>Téléphone principal<star /></template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field outlined v-model="adresse.postePrincipal" :rules="optionalNumberRules" validate-on-blur>
          <template v-slot:label>Poste</template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="8" class="py-0">
        <v-text-field outlined v-model="adresse.telephoneSecondaire" v-facade="'###-###-####'" :rules="optionalPhoneRules" validate-on-blur>
          <template v-slot:label>Téléphone secondaire</template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="py-0">
        <v-text-field outlined v-model="adresse.posteSecondaire" :rules="optionalNumberRules" validate-on-blur>
          <template v-slot:label>Poste</template>
        </v-text-field>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import Rules from "@/mixins/Rules"
import { mapGetters } from "vuex"
export default {
  props: {
    adresse: { type: Object },
    typeAdresseDisabled: { type: Boolean, default: false },
    hidePhoneNumbers: { type: Boolean, default: false }
  },
  mixins: [Rules],
  computed: {
    ...mapGetters(["catalogues"])
  }
}
</script>

<style>
</style>