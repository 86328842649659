<template>
  <div>
    <StepHeadline hideCaption>Résumé de l'inscription</StepHeadline>
    <div class="formulaire-inner">
      <resume-part
        title="Nom"
        :description="`${formulaire.informationsPersonnelles.prenom} ${formulaire.informationsPersonnelles.nom}`"
      ></resume-part>
      <asdeq-separator />

      <div v-if="formulaire.inscription.invites">
        <h4>Liste d'invités:</h4>
        <ul>
          <li v-for="(invite, index) in formulaire.inscription.invites" :key="'invite_resume_' + index" class="mb-2">
            <div class="d-flex justify-space-between">
              <div>
                {{ invite.prenom }} {{ invite.nom }}<br /><span class="font-italic">{{ invite.courriel }}</span>
              </div>
              <div class="primary--text">
                {{ invite.tarif ? invite.tarif.typeTarif : "" }} ({{ invite.tarif ? invite.tarif.montantAvantTaxe : 0 | money }})
              </div>
            </div>
          </li>
        </ul>
      </div>
      <asdeq-separator />
      <h4 class="mb-4">Total des frais d'inscription ($)</h4>
      <Panier :amount="montantTotalActivite" :pasDeTaxes="evenement.pasDeTaxes"></Panier>
    </div>
  </div>
</template>

<script>
import StepHeadline from "@/components/StepHeadline"
import Panier from "@/components/Panier"
import InscriptionActivites from "@/mixins/InscriptionActivites"
export default {
  mixins: [InscriptionActivites],
  props: {
    formulaire: { type: Object }
  },
  components: {
    StepHeadline,
    Panier
  }
}
</script>

<style>
</style>