export default class Adresse {
  typeAdresse;
  nomOrganisme = "";
  titreExact = "";
  adresse = "";
  codePostal = "";
  ville = "";
  province;
  telephonePrincipal = "";
  postePrincipal = "";
  telephoneSecondaire = "";
  posteSecondaire = "";

  constructor(type) {
    this.typeAdresse = type
  }
}