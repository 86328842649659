<template>
  <hr />
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "@/sass/_colors.scss";
hr {
  border-top: 4px solid $baseBlue;
  margin: 2em 0;
}
</style>