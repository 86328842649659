<template>
  <div>
    <StepHeadline>Informations personnelles</StepHeadline>
    <div class="formulaire-inner">
      <v-form v-model="valid" ref="mainForm">
        <v-radio-group row v-model="informationsPersonnelles.salutation" :rules="required" class="mb-3">
          <v-radio v-for="salutation in catalogues.salutations" :key="salutation.id" :value="salutation.id">
            <template v-slot:label>{{ salutation.libelleFR }}<star /></template>
          </v-radio>
        </v-radio-group>

        <v-text-field outlined v-model="informationsPersonnelles.prenom" :rules="required" @blur="updateTarifAndService" @change="updateInvite">
          <template v-slot:label>Votre prénom<star /></template>
        </v-text-field>
        <v-text-field outlined v-model="informationsPersonnelles.nom" :rules="required" @blur="updateTarifAndService" @change="updateInvite">
          <template v-slot:label>Votre nom<star /></template>
        </v-text-field>
        <v-select
          outlined
          v-model="informationsPersonnelles.sectionRegionale"
          :items="catalogues.sectionsRegionales"
          item-text="libelleFR"
          item-value="id"
          :rules="required"
        >
          <template v-slot:label>Choisir une section régionale<star /></template>
        </v-select>
        <v-text-field
          outlined
          v-model="informationsPersonnelles.courriel"
          @change="updateInvite"
          :rules="requiredEmail"
          validate-on-blur
          @blur="updateTarifAndService"
        >
          <template v-slot:label>Votre courriel<star /></template>
        </v-text-field>
        <v-alert text border="left" type="info" v-if="showAlertNonMembre">
          <p>Nous ne vous avons pas reconnu à titre de membre de l’Association.</p>
          <ul>
            <li>Si vous êtes membre, assurez-vous d’utiliser l’adresse courriel associée à votre adhésion.</li>
            <li>Si vous n’êtes pas membre, cliquez sur SUIVANT.</li>
          </ul>
        </v-alert>
      </v-form>
    </div>
  </div>
</template>

<script>
import StepHeadline from "@/components/StepHeadline"
import { mapGetters } from "vuex"
import FormBase from "@/mixins/FormBase"
import Rules from "@/mixins/Rules"
import { bus } from "@/plugins/bus"

export default {
  mixins: [FormBase, Rules],
  components: {
    StepHeadline
  },
  props: {
    formulaire: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["catalogues", "evenement", "estMembre"]),

    informationsPersonnelles() {
      return this.formulaire.informationsPersonnelles
    },
    nomPrenomCourrielValid() {
      return (
        this.informationsPersonnelles.prenom &&
        this.informationsPersonnelles.nom &&
        this.informationsPersonnelles.courriel &&
        /.+@.+\..+/.test(this.informationsPersonnelles.courriel)
      )
    },
    showAlertNonMembre() {
      return !this.estMembre && this.$route.path.includes("inscription") && this.nomPrenomCourrielValid
    }
  },
  watch: {
    nomPrenomCourrielValid() {
      bus.$emit("checkIfMembre")
      bus.$emit("updateTarif")
    }
  },
  mounted() {
    //this.$refs.mainForm.validate()
  },
  methods: {
    updateTarifAndService() {
      if (this.nomPrenomCourrielValid) {
        bus.$emit("checkIfMembre")
        bus.$emit("updateTarif")
      }
    },
    updateInvite() {
      if (this.formulaire.inscription?.invites.length > 0) {
        this.formulaire.inscription.invites[0].nom = this.formulaire.informationsPersonnelles.nom
        this.formulaire.inscription.invites[0].prenom = this.formulaire.informationsPersonnelles.prenom
        this.formulaire.inscription.invites[0].courriel = this.formulaire.informationsPersonnelles.courriel
      }
    }
  }
}
</script>

<style>
</style>