import Vue from 'vue'
import Vuex from 'vuex'
import catalogues from './catalogues'
import evenement from './evenement'
import adhesion from './adhesion'
import statutContact from './statutContact'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    catalogues,
    evenement,
    adhesion,
    statutContact
  }
})
