
export default {
  state: () => ({
    tarifAdhesion: {}
  }),
  getters: {
    tarifAdhesion: (state) => {
      return state.tarifAdhesion
    }
  },
  mutations: {
    SET_TARIF_ADHESION(state, tarifAdhesion) {
      state.tarifAdhesion = tarifAdhesion
    }
  }
}