<template>
  <div>
    <StepHeadline>Coordonnées</StepHeadline>
    <div class="formulaire-inner">
      <v-form v-model="valid" ref="mainForm">
        <Adresse :adresse="adresse1" />
        <template v-if="!uneSeuleAdresse">
          <v-checkbox label="Ajouter une autre adresse" v-model="coordonnees.ajouterUneAutreAdresse"> </v-checkbox>
          <template v-if="coordonnees.ajouterUneAutreAdresse">
            <Adresse :adresse="adresse2" hidePhoneNumbers typeAdresseDisabled />
          </template>
        </template>
      </v-form>
    </div>
  </div>
</template>

<script>
import StepHeadline from "@/components/StepHeadline"
import Adresse from "@/components/Adresse"
import FormBase from "@/mixins/FormBase"
export default {
  mixins: [FormBase],
  components: {
    StepHeadline,
    Adresse
  },
  data() {
    return {
      typeAdresse: 0,
      ajouterUneAutreAdresse: false,
      autreAdresse: {
        type: 0
      }
    }
  },
  props: {
    formulaire: {
      type: Object
    },
    uneSeuleAdresse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    coordonnees() {
      return this.formulaire.coordonnees
    },
    adresse1() {
      return this.coordonnees.adresse1
    },
    adresse2() {
      return this.coordonnees.adresse2
    }
  },
  watch: {
    "coordonnees.adresse1.typeAdresse": function (newValue) {
      if (newValue == "pro") {
        this.coordonnees.adresse2.typeAdresse = "perso"
      } else if (newValue == "perso") {
        this.coordonnees.adresse2.typeAdresse = "pro"
      }
    }
  }
}
</script>

<style>
</style>