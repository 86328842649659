<template>
  <v-main>
    <div class="pa-2 pa-md-12">
      <h1>
        <slot name="title"></slot>
      </h1>
      <p>
        <slot name="message"></slot>
      </p>
      <slot></slot>
    </div>
  </v-main>
</template>

<script>
export default {}
</script>

<style>
</style>