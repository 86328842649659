
export default {
  state: () => ({
    evenement: null,
    uniqueChoixPresenceId: null,
    toutesActivitesMemePresence: false,
  }),
  getters: {
    evenement: (state) => {
      return state.evenement
    },
    uniqueChoixPresenceId: (state) => {
      return state.uniqueChoixPresenceId
    },
    toutesActivitesMemePresence: (state) => {
      return state.toutesActivitesMemePresence
    },
    tarifsUniqueActivite(state, getters, rootState, rootGetters) {
      return state.evenement.listeActivites[0].tarifActivites
        .filter(t => t.tarifDisponiblePour.indexOf(rootGetters.statutContactCode) > -1)
        .sort((a, b) => a.montantAvantTaxe - b.montantAvantTaxe)
    },
    uniqueActivite(state) {
      return state.evenement.listeActivites[0]
    }
  },
  mutations: {
    SET_EVENEMENT(state, evenement) {
      state.evenement = evenement
    },
    CHECK_PRESENCES(state) {
      state.toutesActivitesMemePresence = state.evenement.listeActivites.every(a => a.presence === state.evenement.listeActivites[0].presence)
      if (state.toutesActivitesMemePresence) {
        state.uniqueChoixPresenceId = state.evenement.listeActivites[0].presence
      }
    }
  }
}