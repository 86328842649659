<template>
  <div>
    <StepHeadline hideCaption>{{ title }}</StepHeadline>
    <div class="formulaire-inner">
      <div v-if="!result.isFree">
        <p>Pour diminuer notre empreinte écologique, nous vous suggérons de payer par carte de crédit</p>
        <p class="grey--text font-italic">* Vous serez redirigé vers Paypal pour procéder au paiement</p>
        <div v-html="result.paypalHTML"></div>
        <div class="mt-12">
          <p>
            Si vous choissisez de payer par chèque, veuillez le libeller à l'ordre de l'<strong>{{ ordreDe }}</strong>
            et envoyer votre paiement complet à l'adresse suivante :
          </p>
          <p class="mb-0">{{ emetteurFacture.rue1 }}</p>
          <p class="mb-0">{{ emetteurFacture.rue2 }}</p>
          <p>{{ emetteurFacture.ville }} ({{ emetteurFacture.province }}) {{ emetteurFacture.codePostal }}</p>
        </div>
      </div>

      <div class="text-center">
        <v-btn class="mt-6" color="accent" @click="$emit('terminer')" large>Terminer</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import StepHeadline from "@/components/StepHeadline"
import { mapGetters } from "vuex"
export default {
  components: { StepHeadline },
  props: {
    result: { type: Object },
    title: String
  },
  data() {
    return {
      emetteurDefaut: {
        libelle: "Association des économistes québécois",
        rue1: "C.P. 18010",
        rue2: "Succursale Sainte-Rose",
        ville: "Laval",
        province: "Québec",
        codePostal: "H7L 6B2"
      }
    }
  },
  computed: {
    ...mapGetters(["evenement"]),
    ordreDe() {
      if (this.emetteurFacture.organisme) {
        return `${this.emetteurFacture.organisme} -  ${this.emetteurFacture.libelle}`
      }
      return this.emetteurFacture.libelle
    },
    emetteurFacture() {
      return this.evenement?.emetteurFacture ?? this.emetteurDefaut
    }
  }
}
</script>

<style>
</style>