<template>
  <div>
    <StepHeadline hideCaption>Résumé de votre adhésion</StepHeadline>
    <div class="formulaire-inner">
      <resume-part
        title="Nom"
        :description="`${formulaire.informationsPersonnelles.prenom} ${formulaire.informationsPersonnelles.nom}`"
      ></resume-part>
      <template v-if="formulaire.coordonnees.adresse1.typeAdresse == 'pro'">
        <resume-part title="Nom de l'organisme" :description="`${formulaire.coordonnees.adresse1.nomOrganisme}`"></resume-part>
      </template>
      <template v-if="formulaire.coordonnees.ajouterUneAutreAdresse && formulaire.coordonnees.adresse2.typeAdresse == 'pro'">
        <resume-part title="Nom de l'organisme" :description="`${formulaire.coordonnees.adresse2.nomOrganisme}`"></resume-part>
      </template>
      <resume-part title="Produit" v-if="tarifAdhesion" :description="tarifAdhesion.nomProduit"></resume-part>
      <asdeq-separator />
      <h4 class="mb-4">Total des frais d'adhésion ($)</h4>
      <Panier v-if="tarifAdhesion" :amount="tarifAdhesion.montant"></Panier>
      <v-checkbox label="Je désire avoir un reçu" v-model="formulaire.informationsProfessionnelles.desireAvoirRecu"></v-checkbox>
    </div>
  </div>
</template>

<script>
import StepHeadline from "@/components/StepHeadline"
import Panier from "@/components/Panier"
import { mapGetters } from "vuex"
export default {
  props: {
    formulaire: { type: Object }
  },
  components: {
    StepHeadline,
    Panier
  },
  computed: {
    ...mapGetters(["tarifAdhesion"])
  }
}
</script>

<style>
</style>