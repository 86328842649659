<template>
  <FormulaireLayout :steps="steps" :loading="loading" :currentStepIndex="currentStepIndex" @goToStep="goToStep">
    <template v-slot:title>{{ evenement ? evenement.nomEvenement : "Inscription à un événement" }}</template>
    <template v-slot:subtitle>{{ evenement ? evenement.sousTitreEvenement : "" }}</template>
    <div v-for="s in steps" :key="s.path">
      <div v-show="step == s.path">
        <component :is="s.path" @validationChanged="setState(s.path, $event)" :formulaire="formulaire" :ref="s.path">
          <h1>{{ s.path }}</h1>
        </component>
        <div class="d-flex justify-space-between formulaire-inner">
          <div>
            <v-btn class="mt-6" large @click="back" outlined color="accent" v-if="!isFirstStep">Précédent</v-btn>
          </div>
          <div>
            <v-btn class="mt-6" large @click="next" color="accent" v-if="!isLastStep">Suivant</v-btn>
            <v-btn class="mt-6" large @click="submit" color="accent" v-else-if="isLastStep">Confirmer</v-btn>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step == 'confirmation'">
      <Confirmation title="Payer votre inscription" :result="inscriptionResult" @terminer="$router.push({ path: url + 'remerciement' })" />
    </div>
    <div v-if="step == 'remerciement'">
      <Remerciement title="Merci de votre inscription" />
    </div>
  </FormulaireLayout>
</template>

<script>
import FormulaireBase from "@/mixins/FormulaireBase"
import InformationsPersonnelles from "@/components/InformationsPersonnelles"
import Coordonnees from "@/components/Coordonnees"
import Confirmation from "@/components/Confirmation"
import Inscription from "@/components/Inscription"
import ResumeInscription from "@/components/ResumeInscription"
import Remerciement from "@/components/Remerciement"
import InformationsProfessionnelles from "@/components/InformationsProfessionnelles"
import FormulaireEvenement from "@/models/FormulaireEvenement"
import { InscriptionCongres, CheckIfMembre, GetEvenement } from "@/plugins/api"
import { bus } from "@/plugins/bus"
import { mapGetters, mapMutations } from "vuex"
import InscriptionActivites from "@/mixins/InscriptionActivites"

export default {
  mixins: [FormulaireBase, InscriptionActivites],
  props: {
    evenementSlug: String
  },
  data() {
    return {
      steps: [
        { path: "informations-personnelles", id: 0, active: false, valid: true, name: "Informations personnelles" },
        { path: "coordonnees", id: 1, active: true, valid: true, name: "Coordonnées" },
        { path: "informations-professionnelles", id: 2, active: false, valid: true, name: "Informations professionnelles" },
        { path: "inscription", id: 3, active: false, valid: true, name: "Inscription" },
        { path: "resume-inscription", id: 4, active: false, valid: true, name: "Résumé de l'inscription" }
      ],
      formulaire: new FormulaireEvenement(),
      cataloguesToLoad: [
        "salutations",
        "sectionsRegionales",
        "provinces",
        "villes",
        "etudes",
        "statuts",
        "milieux",
        "champsActivite",
        "presences",
        "restrictionsAlimentaires"
      ],
      inscriptionResult: null
    }
  },

  computed: {
    ...mapGetters(["evenement", "uniqueChoixPresenceId"]),
    url() {
      return `/inscription-evenement/${this.evenementSlug}/`
    }
  },

  methods: {
    ...mapMutations(["SET_EVENEMENT", "SET_STATUT_CONTACT", "CHECK_PRESENCES"]),
    submitCustom() {
      this.loading = true
      InscriptionCongres(this.formulaire, this.evenementSlug, this.principales, this.activitesSelectionnes).then((res) => {
        this.loading = false
        this.currentStepIndex++
        this.inscriptionResult = res.data
        this.$vuetify.goTo(0)
        this.$router.push({
          path: this.url + "confirmation",
          query: { ...this.$route.query }
        })
      })
    },
    updateMembre() {
      CheckIfMembre(this.formulaire).then((res) => {
        this.SET_STATUT_CONTACT(res.data)
      })
    },
    getEvenement() {
      this.loading = true
      GetEvenement(this.evenementSlug).then((res) => {
        this.SET_EVENEMENT(res.data.evenement)
        this.CHECK_PRESENCES()
        this.formulaire.inscription.presence = this.uniqueChoixPresenceId
        this.loading = false
      })
    }
  },

  mounted() {
    bus.$on("checkIfMembre", this.updateMembre)
    this.updateMembre()
    this.getEvenement()
  },

  components: {
    InformationsPersonnelles,
    Coordonnees,
    InformationsProfessionnelles,
    Inscription,
    ResumeInscription,
    Confirmation,
    Remerciement
  },

  metaInfo() {
    return {
      title: "ASDEQ | Inscription au Congrès"
    }
  }
}
</script>

<style>
</style>
