<template>
  <div class="text-center">
    <v-icon size="100" color="#93d500">mdi-check-circle-outline</v-icon>
    <h3 class="title mb-6">{{ title }}</h3>
    <div class="formulaire-inner">
      <div class="text-left">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

<style>
</style>