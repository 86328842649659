
export default {
  state: () => ({
    catalogues: {}
  }),
  getters: {
    catalogues: (state) => {
      return state.catalogues
    }
  },
  mutations: {
    SET_CATALOGUES(state, { id, values }) {
      state.catalogues[id] = values
    }
  }
}