<template>
  <div>
    <h4>{{ title }} :</h4>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String
    }
  }
}
</script>

<style>
</style>