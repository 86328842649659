<template>
  <div class="text-center" :class="{ 'my-12': !noMargin }">
    <v-progress-circular indeterminate color="primary" :size="size" :width="width"><slot v-if="!noText">Chargement...</slot></v-progress-circular>
  </div>
</template>

<script>
export default {
  props: {
    size: { type: String, default: "200" },
    width: { type: String, default: "10" },
    noText: { type: Boolean, default: false },
    noMargin: { type: Boolean, default: false }
  }
}
</script>
