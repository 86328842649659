<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",

  metaInfo() {
    return {
      title: "ASDEQ - Association des économistes du Québec"
    }
  },

  components: {},

  data: () => ({
    //
  })
}
</script>

<style lang="scss">
@import "@/sass/main.scss";

.theme--light.v-application {
  background-color: $baseWhite !important;
}

.list_counter_validator > .v-input__control > .v-input__slot {
  display: none !important;
}
.list_counter_validator > .v-input__control > .v-messages .v-messages__message {
  font-size: 1.5em;
  line-height: 1.4em;
  margin-top: 20px;
  margin-left: -20px;
}
</style>
