export default {
  data() {
    return {
      required: [(v) => !!v || 'Ce champ est obligatoire'],
      multipleSelectRequired: [(v) => v.length > 0 || 'Ce champ est obligatoire'],
      requiredEmail: [(v) => /.+@.+\..+/.test(v) || "Courriel non valide"],
      codePostalRules: [(v) => /^[A-Z]\d[A-Z] \d[A-Z]\d$/.test(v) || "Code postal non valide"],
      phoneRules: [(v) => /^\d{3}-\d{3}-\d{4}$/.test(v) || "Téléphone non valide"],
      optionalPhoneRules: [(v) => !v || /^\d{3}-\d{3}-\d{4}$/.test(v) || "Téléphone non valide"],
      optionalNumberRules: [(v) => !v || /^\d+$/.test(v) || "Le poste doit être un nombre"]
    }
  }
}
