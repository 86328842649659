<template>
  <FormulaireLayout :steps="steps" :loading="loading">
    <template v-slot:title>Abonnement à l'infolettre</template>
    <div v-for="s in steps" :key="s.path">
      <div v-show="step == s.path">
        <component :is="s.path" @validationChanged="setState(s.path, $event)" :formulaire="formulaire" :ref="s.path">
          <h1>{{ s.path }}</h1>
        </component>
        <div class="d-flex justify-space-between formulaire-inner">
          <div>
            <v-btn class="mt-6" large @click="back" outlined color="accent" v-if="!isFirstStep">Précédent</v-btn>
          </div>
          <div>
            <v-btn class="mt-6" large @click="next" color="accent" v-if="!isLastStep">Suivant</v-btn>
            <v-btn class="mt-6" large @click="submit" color="accent" v-else-if="isLastStep">Confirmer</v-btn>
          </div>
        </div>
      </div>
    </div>
    <div v-show="step == 'remerciement'">
      <Remerciement title="Merci de votre abonnement" />
    </div>
  </FormulaireLayout>
</template>

<script>
import FormulaireBase from "@/mixins/FormulaireBase"
import InformationsPersonnelles from "@/components/InformationsPersonnelles"
import FormulaireInfolettre from "@/models/FormulaireInfolettre"
import { AbonnementInfolettre } from "@/plugins/api"
import Remerciement from "@/components/Remerciement"
export default {
  mixins: [FormulaireBase],
  data() {
    return {
      url: "/abonnement-infolettre/",
      steps: [{ path: "informations-personnelles", id: 0, valid: true, name: "Informations personnelles" }],
      formulaire: new FormulaireInfolettre(),
      cataloguesToLoad: ["salutations", "sectionsRegionales"]
    }
  },

  components: {
    InformationsPersonnelles,
    Remerciement
  },

  metaInfo() {
    return {
      title: "ASDEQ | Abonnement à l’infolettre"
    }
  },

  methods: {
    submitCustom() {
      this.loading = true
      AbonnementInfolettre(this.formulaire).then(() => {
        this.loading = false
        this.currentStepIndex++
        this.$vuetify.goTo(0)
        this.$router.push({
          path: this.url + "remerciement",
          query: { ...this.$route.query }
        })
      })
    }
  }
}
</script>

<style>
</style>
