<template>
  <FormulaireLayout :steps="steps" :loading="loading" :currentStepIndex="currentStepIndex" @goToStep="goToStep">
    <template v-slot:title>{{ service }}</template>

    <div v-for="s in steps" :key="s.path">
      <div v-show="step == s.path">
        <component :is="s.path" @validationChanged="setState(s.path, $event)" :formulaire="formulaire" :ref="s.path">
          <h1>{{ s.path }}</h1>
        </component>
        <div class="d-flex justify-space-between formulaire-inner">
          <div>
            <v-btn class="mt-6" large @click="back" outlined color="accent" v-if="!isFirstStep">Précédent</v-btn>
          </div>
          <div>
            <v-btn class="mt-6" large @click="next" color="accent" v-if="!isLastStep">Suivant</v-btn>
            <v-btn class="mt-6" large @click="submit" color="accent" v-else-if="isLastStep">Confirmer</v-btn>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step == 'confirmation'">
      <Confirmation
        :title="`${adhesionResult.isFree ? 'Terminer' : 'Payer'} votre ${serviceShort}`"
        :result="adhesionResult"
        @terminer="$router.push({ path: url + 'remerciement' })"
      />
    </div>
    <div v-if="step == 'remerciement'">
      <Remerciement :title="`Merci de votre ${serviceShort}`">
        <asdeq-separator></asdeq-separator>
        <p>L’Association des économistes québécois est fière de vous compter parmi ses membres!</p>
        <p>
          Nous vous invitons à consulter fréquemment notre site Web, à participer aux nombreuses activités organisées par les sections régionales, à
          nous suivre sur les réseaux sociaux <a href="https://www.facebook.com/economistesquebecois">Facebook</a>,
          <a href="https://www.linkedin.com/company/association-des-economistes-quebecois">LinkedIn</a> et
          <a href="https://twitter.com/EconomistesQc">Twitter</a> et à profiter des privilèges que confère votre adhésion comme celui d’être
          informé(e), dès leur publication, des offres d’emploi du Carrefour de l’emploi.
        </p>
        <asdeq-separator></asdeq-separator>
      </Remerciement>
    </div>
  </FormulaireLayout>
</template>

<script>
import FormulaireBase from "@/mixins/FormulaireBase"
import InformationsPersonnelles from "@/components/InformationsPersonnelles"
import Coordonnees from "@/components/Coordonnees"
import Confirmation from "@/components/Confirmation"
import ResumeAdhesion from "@/components/ResumeAdhesion"
import Remerciement from "@/components/Remerciement"
import InformationsProfessionnelles from "@/components/InformationsProfessionnelles"
import FormulaireAdhesion from "@/models/FormulaireAdhesion"
import { CreateAdhesion, TarifAdhesion, CheckIfMembre } from "@/plugins/api"
import { bus } from "@/plugins/bus"
import { mapGetters, mapMutations } from "vuex"

export default {
  mixins: [FormulaireBase],
  data() {
    return {
      url: "/formulaire-adhesion/",
      steps: [
        { path: "informations-personnelles", id: 0, active: false, valid: true, name: "Informations personnelles" },
        { path: "coordonnees", id: 1, active: true, valid: true, name: "Coordonnées" },
        { path: "informations-professionnelles", id: 2, active: false, valid: true, name: "Informations professionnelles" },
        { path: "resume-adhesion", id: 3, active: false, valid: true, name: "Résumé de l'adhésion" }
      ],
      formulaire: new FormulaireAdhesion(),
      cataloguesToLoad: ["salutations", "sectionsRegionales", "provinces", "villes", "etudes", "statuts", "milieux", "champsActivite"],
      adhesionResult: null
    }
  },
  computed: {
    ...mapGetters(["service", "serviceShort"])
  },
  methods: {
    ...mapMutations(["SET_TARIF_ADHESION", "SET_STATUT_CONTACT"]),
    submitCustom() {
      this.loading = true
      CreateAdhesion(this.formulaire).then((res) => {
        this.loading = false
        this.currentStepIndex++
        this.adhesionResult = res.data
        this.$vuetify.goTo(0)
        this.$router.push({
          path: this.url + "confirmation",
          query: { ...this.$route.query }
        })
      })
    },
    updateTarif() {
      TarifAdhesion(this.formulaire).then((res) => {
        this.SET_TARIF_ADHESION(res.data)
      })
    },
    updateService() {
      CheckIfMembre(this.formulaire).then((res) => {
        this.SET_STATUT_CONTACT(res.data)
      })
    }
  },

  mounted() {
    bus.$on("updateTarif", this.updateTarif)
    this.updateTarif()
    bus.$on("checkIfMembre", this.updateService)
    this.updateService()
  },

  components: {
    InformationsPersonnelles,
    Coordonnees,
    InformationsProfessionnelles,
    ResumeAdhesion,
    Confirmation,
    Remerciement
  },

  metaInfo() {
    return {
      title: "ASDEQ | Adhésion ou renouvellement d'adhésion"
    }
  }
}
</script>

<style>
</style>
