<template>
  <div class="d-flex justify-space-between">
    <span>{{ title }}</span>
    <span class="flex-grow-1 filling mx-3"></span>
    <span>{{ amount | money }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
    amount: { type: Number }
  }
}
</script>

<style>
.filling {
  border-bottom: 1px dashed #ddd;
}
</style>