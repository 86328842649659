<template>
  <div class="stepHeadline mb-6">
    <h1 class="stepTitle mb-3"><slot></slot></h1>
    <p class="stepCaption" v-if="!hideCaption">Les champs identifiés d'un astérisque (<star />) sont obligatoires.</p>
  </div>
</template>

<script>
export default {
  props: {
    hideCaption: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss">
@import "@/sass/_colors.scss";
.stepHeadline {
  text-align: center;
  .stepTitle {
    font-weight: 900;
    font-size: 2.5rem;
  }
  .stepCaption {
    font-weight: 400;
    color: rgba(black, 0.6);
    font-size: 0.9rem;
  }
}
</style>