<template>
  <span class="mandatory-star">*</span>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "@/sass/_colors.scss";
.mandatory-star {
  color: $accent1;
}
</style>