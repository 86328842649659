<template>
  <div>
    <LignePanier title="Frais avant taxes" :amount="amountArrondi"></LignePanier>
    <LignePanier title="TPS" :amount="tps" v-if="!pasDeTaxes"></LignePanier>
    <LignePanier title="TVQ" :amount="tvq" v-if="!pasDeTaxes"></LignePanier>
    <p v-if="pasDeTaxes" class="font-italic my-2">Aucune taxe pour cette activité.</p>
    <LignePanier title="Total" :amount="total"></LignePanier>
  </div>
</template>

<script>
import LignePanier from "@/components/LignePanier"
export default {
  props: {
    amount: { type: Number },
    pasDeTaxes: { type: Boolean, default: false }
  },
  components: { LignePanier },
  computed: {
    amountArrondi() {
      return Math.round(this.amount * 100) / 100
    },
    tps() {
      return this.pasDeTaxes ? 0 : Math.round(this.amountArrondi * 100 * 0.05) / 100
    },
    tvq() {
      return this.pasDeTaxes ? 0 : Math.round(this.amountArrondi * 100 * 0.09975) / 100
    },
    total() {
      return this.amount + this.tps + this.tvq
    }
  }
}
</script>

<style>
</style>