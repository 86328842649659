<template>
  <ErreurLayout>
    <template v-slot:title>Une erreur est survenue</template>
    <template v-slot:message>Une erreur non gérée s'est produite, veuillez réessayer plus tard.</template>
  </ErreurLayout>
</template>

<script>
import ErreurLayout from "@/layouts/Erreur"
export default {
  components: { ErreurLayout }
}
</script>

<style>
</style>