import { mapGetters } from "vuex"
export default {
  computed: {
    ...mapGetters(["statutContactCode", "evenement", "catalogues", "toutesActivitesMemePresence", "uniqueChoixPresenceId", "tarifsUniqueActivite"]),
    activites() {
      return this.evenement ? this.evenement.listeActivites.filter(this.filterActiviteALaCarte).sort(this.sortActivites).map(this.mapActivite) : []
    },
    activitesNonCompletes() {
      return this.activites.filter(a => !a.complet)
    },
    formations() {
      return this.evenement ? this.evenement.listeActivites.filter(this.filterActiviteFormation).sort(this.sortActivites).map(this.mapActivite) : []
    },
    principales() {
      return this.evenement ? this.evenement.listeActivites.filter(this.filterActiviteFull).sort(this.sortActivites).map(this.mapActivite) : []
    },
    toutesActivites() {
      return this.activites.concat(this.formations)
    },
    activitesSelectionnes() {
      if (this.evenement.typeEvenement !== 'CONGRES') {
        return [this.toutesActivites[0]]
      }
      let concat = this.formulaire.inscription.activitesALaCarte.concat(this.formulaire.inscription.ateliersDeFormation)
      return this.toutesActivites
        .filter((a) => concat.some((ai) => ai === a.produitId))
    },
    estComplete() {
      return this.formulaire.inscription.inscriptionComplete === "oui"
    },
    montantTotalActivites() {
      if (!this.showTarification) {
        return 0
      }
      return (
        this.activitesSelectionnes
          .map((a) => a.afficherTousLesTarifs ? this.findTarifSelectionne(a).montantAvantTaxe : a.tarif.montantAvantTaxe)
          .reduce((accumulator, curr) => accumulator + curr, 0) +
        (this.estComplete ? this.principales[0].tarif.montantAvantTaxe : 0)
      )
    },
    montantTotalActivite() {
      let adder = (accumulator, curr) => {
        return accumulator + (curr.tarif ? curr.tarif.montantAvantTaxe : 0)
      }
      return this.formulaire.inscription.invites.reduce(adder, 0)
    },
    enPresentielle() {
      return this.formulaire.inscription.presence === 310
    },
    enVirtuelle() {
      return this.formulaire.inscription.presence === 311
    },
    showTarification() {
      return (
        this.formulaire.inscription.presence && (this.activitesSelectionnes.length > 0 || this.estComplete)
      )
    },
    showTarificationActivite() {
      return (
        this.formulaire.inscription.invites.length > 0
      )
    },
  },
  methods: {
    estBonType(a, type) {
      return a.type === type
    },
    estNonComplet(a) {
      return !a.complet
    },
    estBonnePresence(a) {
      return this.formulaire.inscription.presence === a.presence
    },
    findTarif(tarifs) {
      let found = tarifs.find(
        (t) => t.statutEmploi === this.formulaire.informationsProfessionnelles.statut && t.tarifDisponiblePour.indexOf(this.statutContactCode) > -1
      )
      return found ?? tarifs[0]
    },
    findTarifSelectionne(activite) {
      let typeTarifSelctionne = this.formulaire.inscription.tarifsSelectionnes.find(t => t && t.startsWith(`${activite.produitId}|`))
      if (typeTarifSelctionne) {
        let found = activite.tarifs.find(
          (t) => t.typeTarifId == typeTarifSelctionne.split('|')[1]
        )
        return found ?? activite.tarifs[0]
      }
      return activite.tarifs[0]
    },
    mapActivite(a) {
      return {
        nomActivite: a.nomActivite,
        id: a.id,
        produitId: a.produitId,
        debut: a.debut,
        type: a.type,
        complet: a.complet,
        presence: a.presence,
        tarif: this.findTarif(a.tarifActivites),
        tarifSelectionne: undefined,
        tarifs: a.tarifActivites,
        afficherTousLesTarifs: a.afficherTousLesTarifs,
        categorieProduit: a.categorieProduit
      }
    },
    sortActivites(a, b) {
      return new Date(a.debut) - new Date(b.debut)
    },
    filterActivite(a, type) {
      return this.estBonType(a, type) && this.estBonnePresence(a)
    },
    filterActiviteALaCarte(a) {
      return this.filterActivite(a, "A_LA_CARTE")
    },
    filterActiviteFormation(a) {
      return this.filterActivite(a, "FORMATION")
    },
    filterActiviteFull(a) {
      return this.filterActivite(a, "FULL")
    },
    getPresenceFr(presenceId) {
      if (this.catalogues && this.catalogues.presences) {
        let found = this.catalogues.presences.find(p => p.id == presenceId)
        if (found) {
          return found.libelleFR

        }
      }
      return ''
    }
  }
}