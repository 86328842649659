<template>
  <div>
    <StepHeadline>Inscription</StepHeadline>
    <div class="formulaire-inner mb-3">
      <v-form v-model="valid" ref="mainForm">
        <h2 class="mb-3">Voici les tarifs disponibles :</h2>
        <v-row v-for="tarif in tarifsUniqueActivite" :key="tarif.typeTarifId" no-gutters>
          <v-col cols="4">
            {{ tarif.typeTarif }}
          </v-col>
          <v-col cols="8" class="primary--text">
            {{ tarif.montantAvantTaxe | money }}
          </v-col>
        </v-row>
        <asdeq-separator />

        <div>
          <Question number="1" class="mb-6">Indiquez ci-dessous les participants</Question>
          <v-expansion-panels class="mb-6" multiple v-model="invitesOpened">
            <v-expansion-panel v-for="(invite, index) in formulaire.inscription.invites" :key="'invite_' + index">
              <v-expansion-panel-header disable-icon-rotate>
                <div>
                  <v-icon v-if="!inviteValide(invite)" color="warning" class="mr-2">mdi-alert</v-icon>
                  <span class="mb-2"> {{ index + 1 }}. {{ invite.prenom }} {{ invite.nom }} </span>
                  <v-chip x-small color="primary" class="ml-2" v-if="invitePrincipal === invite">Principal</v-chip>
                </div>
                <template v-slot:actions>
                  <v-btn color="error" v-if="invitePrincipal !== invite" text @click="removeInvite(invite)" small>Retirer</v-btn>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <Invite :readOnly="invitePrincipal === invite" :enPresentielle="enPresentielle" :invite="invite" class="mb-3" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="text-center">
            <v-btn @click="addEmptyInvite" text color="primary"><v-icon class="mr-3">mdi-account-plus</v-icon> Ajouter un participant</v-btn>
          </div>
        </div>

        <div v-if="showTarificationActivite">
          <asdeq-separator />
          <div>
            <h3 class="title">Tarification</h3>
            <Panier :amount="montantTotalActivite" :pasDeTaxes="evenement.pasDeTaxes" />
          </div>
        </div>
        <v-checkbox
          class="list_counter_validator my-2 ml-6 p-0"
          :input-value="formulaire.inscription.invites.length > 0"
          :rules="[(v) => !!v || '']"
          hide-details=""
        ></v-checkbox>
        <v-alert text border="left" type="error" v-if="formulaire.inscription.invites.length === 0">
          Veuillez ajouter au moins un participant.
        </v-alert>
      </v-form>
    </div>
  </div>
</template>

<script>
import StepHeadline from "@/components/StepHeadline"
import Question from "@/components/Question"
import Invite from "@/components/Invite"
import Panier from "@/components/Panier"
import Rules from "@/mixins/Rules"
import FormBase from "@/mixins/FormBase"
import InviteModel from "@/models/Invite"
import InscriptionActivites from "@/mixins/InscriptionActivites"
import { mapGetters } from "vuex"
export default {
  mixins: [Rules, FormBase, InscriptionActivites],
  props: {
    formulaire: { type: Object }
  },
  computed: {
    ...mapGetters(["tarifsUniqueActivite"]),
    invitePrincipal() {
      let inscritPrincipal = this.formulaire.inscription.invites[0]
      return inscritPrincipal
    }
  },
  components: {
    StepHeadline,
    Question,
    Panier,
    Invite
  },
  data() {
    return {
      invitesOpened: []
    }
  },
  methods: {
    addEmptyInvite() {
      this.formulaire.inscription.invites.push(new InviteModel())
      this.invitesOpened.push(this.formulaire.inscription.invites.length - 1)
    },
    removeInvite(invite) {
      let index = this.formulaire.inscription.invites.indexOf(invite)
      this.formulaire.inscription.invites.splice(index, 1)
    },
    inviteValide(invite) {
      return (
        invite.nom &&
        invite.prenom &&
        invite.courriel &&
        invite.tarif &&
        (this.enVirtuelle ||
          (this.enPresentielle &&
            ((invite.hasRestrictionsAlimentaires === "oui" && invite.restrictionsAlimentaires.length > 0) ||
              invite.hasRestrictionsAlimentaires === "non")))
      )
    }
  }
}
</script>

<style>
</style>