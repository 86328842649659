import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import AbonnementInfolettre from '@/views/AbonnementInfolettre'
import Adhesion from '@/views/Adhesion'
import InscriptionEvenement from '@/views/InscriptionEvenement'
import InscriptionActivite from '@/views/InscriptionActivite'
import Erreur from '@/views/Erreur'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/abonnement-infolettre',
    component: AbonnementInfolettre,
    props: true
  },
  {
    path: '/abonnement-infolettre/:step',
    component: AbonnementInfolettre,
    props: true
  },
  {
    path: '/formulaire-adhesion',
    component: Adhesion,
    props: true
  },
  {
    path: '/formulaire-adhesion/:step',
    component: Adhesion,
    props: true
  },
  {
    path: '/inscription-evenement/:evenementSlug',
    component: InscriptionEvenement,
    props: true
  },
  {
    path: '/inscription-evenement/:evenementSlug/:step',
    component: InscriptionEvenement,
    props: true
  },
  {
    path: '/inscription-activite/:evenementSlug',
    component: InscriptionActivite,
    props: true
  },
  {
    path: '/inscription-activite/:evenementSlug/:step',
    component: InscriptionActivite,
    props: true
  },
  {
    path: '/erreur',
    component: Erreur,
    name: "Erreur"
  }
]

const router = new VueRouter({
  routes
})

export default router
