<template>
  <div>
    <StepHeadline>Inscription</StepHeadline>
    <div class="formulaire-inner mb-3">
      <v-form v-model="valid" ref="mainForm">
        <div class="mb-6">
          <Question number="1" class="mb-3">Souhaitez-vous vous inscrire à l'ensemble du congrès?</Question>
          <p>
            Une inscription à l'ensemble du congrès inclut une <strong>adhésion</strong> ou <strong>renouvellement gratuit</strong>
            d'un an à l'Association.
          </p>
          <v-radio-group
            row
            v-model="formulaire.inscription.inscriptionComplete"
            :rules="required"
            @change="formulaire.inscription.activitesALaCarte = []"
          >
            <v-radio value="oui" label="Oui">Oui</v-radio>
            <v-radio value="non" label="Non">Non</v-radio>
          </v-radio-group>
        </div>
        <div v-if="!toutesActivitesMemePresence">
          <asdeq-separator />
          <Question number="2">Souhaitez-vous vous inscrire à la version présentielle ou virtuelle?</Question>
          <v-radio-group v-model="formulaire.inscription.presence" :rules="required" row @change="formulaire.inscription.activitesALaCarte = []">
            <v-radio v-for="presence in catalogues.presences" :key="presence.id" :value="presence.id" :label="presence.libelleFR"> </v-radio>
          </v-radio-group>
        </div>
        <div v-else>
          <asdeq-separator />
          <Question number="2" hideStar
            >Ce congrès est offert uniquement en forme <span class="font-weight-bold">{{ getPresenceFr(uniqueChoixPresenceId).toLowerCase() }}</span
            >.</Question
          >
        </div>
        <div v-if="estComplete">
          <v-checkbox v-for="f in principales" :key="f.id" v-model="formulaire.inscription.inscriptionComplete" value="oui" hide-details disabled>
            <template v-slot:label>
              <div>
                <div>{{ f.nomActivite }}</div>
              </div>
            </template>
            <template v-slot:append>
              <span v-if="f.complet" class="font-italic">Complet</span>
              <span v-else class="primary--text">{{ f.tarif.montantAvantTaxe | money }}</span>
            </template>
          </v-checkbox>
        </div>
        <div v-if="activites && activites.length > 0 && formulaire.inscription.inscriptionComplete === 'non'" class="mb-3">
          <asdeq-separator />
          <Question number="3">Choix des activités à la carte:</Question>
          <div v-for="(activite, index) in activites" :key="activite.id">
            <v-checkbox v-model="formulaire.inscription.activitesALaCarte" :value="activite.produitId" hide-details :disabled="activite.complet">
              <template v-slot:label>
                <div>
                  <div>{{ activite.nomActivite }}</div>
                </div>
              </template>
              <template v-slot:append>
                <span v-if="activite.complet" class="font-italic">Complet</span>
                <span v-else-if="!activite.afficherTousLesTarifs">{{ activite.tarif.montantAvantTaxe | money }}</span>
                <span v-else-if="activite.afficherTousLesTarifs">Options...</span>
              </template>
            </v-checkbox>
            <v-select
              v-if="
                !activite.complet && activite.afficherTousLesTarifs && formulaire.inscription.activitesALaCarte.some((a) => a === activite.produitId)
              "
              :items="activite.tarifs"
              v-model="formulaire.inscription.tarifsSelectionnes[index]"
              label="Choix de l'option"
              outlined
              dense
              class="mt-2"
              item-text="typeTarif"
              :item-value="(i) => activite.produitId + '|' + i.typeTarifId + '|' + i.typeTarif"
              :rules="[(v) => !!v || !formulaire.inscription.activitesALaCarte.some((a) => a === activite.produitId) || 'Obligatoire']"
            >
              <template v-slot:item="{ item }">
                <span>{{ item.typeTarif }} &mdash; {{ item.montantAvantTaxe | money }}</span></template
              >
              <template v-slot:selection="{ item }">
                <span class="primary--text">{{ item.typeTarif }} &mdash; {{ item.montantAvantTaxe | money }}</span></template
              >
            </v-select>
          </div>

          <v-alert
            type="info"
            text
            border="left"
            class="mt-3"
            v-if="enVirtuelle && formulaire.inscription.activitesALaCarte.length === activitesNonCompletes.length"
          >
            Nous vous suggérons de vous inscrire à l'ensemble du Congrès pour bénéficier gratuitement d'une adhésion ou d'un renouvellement d'adhésion
            pour un an.
          </v-alert>
        </div>

        <div v-if="enPresentielle">
          <asdeq-separator />
          <Question :number="estComplete || !formulaire.inscription.inscriptionComplete ? '3' : '4'" class="mb-3"
            >Avez vous des restrictions alimentaires?</Question
          >
          <v-radio-group
            row
            v-model="formulaire.inscription.hasRestrictionsAlimentaires"
            :rules="required"
            @change="formulaire.inscription.restrictionsAlimentaires = []"
          >
            <v-radio value="oui" label="Oui">Oui</v-radio>
            <v-radio value="non" label="Non">Non</v-radio>
          </v-radio-group>
          <v-select
            v-if="formulaire.inscription.hasRestrictionsAlimentaires === 'oui'"
            outlined
            v-model="formulaire.informationsPersonnelles.restrictionsAlimentaires"
            multiple
            :items="catalogues.restrictionsAlimentaires"
            item-text="libelleFR"
            item-value="id"
            :rules="multipleSelectRequired"
            label="Veuillez sélectionner un choix"
          >
          </v-select>
        </div>
        <div v-if="formations && formations.length > 0">
          <asdeq-separator />
          <Question hideStar :number="estComplete ? (enPresentielle ? '4' : '3') : enPresentielle ? '5' : '4'" class="mb-3"
            >Souhaitez-vous vous inscrire à un atelier de formation?</Question
          >
          <v-checkbox
            v-for="formation in formations"
            :key="formation.id"
            v-model="formulaire.inscription.ateliersDeFormation"
            :value="formation.produitId"
            hide-details
            :disabled="formation.complet"
            :rules="required"
          >
            <template v-slot:label>
              <div>
                <div>{{ formation.nomActivite }}</div>
              </div>
            </template>
            <template v-slot:append>
              <span v-if="formation.complet" class="font-italic">Complet</span>
              <span v-else>{{ formation.tarif.montantAvantTaxe | money }}</span>
            </template>
          </v-checkbox>
        </div>

        <div v-if="showTarification">
          <asdeq-separator />
          <div>
            <h3 class="title">Tarification</h3>
            <Panier :amount="montantTotalActivites" />
          </div>
        </div>
        <v-checkbox
          class="list_counter_validator my-2 ml-6 p-0"
          :input-value="estComplete || !formulaire.inscription.presence || activitesSelectionnes.length > 0"
          :rules="[(v) => !!v || '']"
          hide-details=""
        ></v-checkbox>
        <v-alert text border="left" type="error" v-if="!(estComplete || !formulaire.inscription.presence || activitesSelectionnes.length > 0)"
          >Veuillez choisir au moins une activité/formation ou vous inscrire à l'ensemble du congrès à la question 1.</v-alert
        >
      </v-form>
    </div>
  </div>
</template>

<script>
import StepHeadline from "@/components/StepHeadline"
import Question from "@/components/Question"
import Panier from "@/components/Panier"
import Rules from "@/mixins/Rules"
import FormBase from "@/mixins/FormBase"
import InscriptionActivites from "@/mixins/InscriptionActivites"
export default {
  mixins: [Rules, FormBase, InscriptionActivites],
  props: {
    formulaire: { type: Object }
  },
  components: {
    StepHeadline,
    Question,
    Panier
  }
}
</script>

<style>
</style>