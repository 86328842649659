<template>
  <div>
    <span class="primary--text font-weight-bold">{{ number }}. </span><span><slot name="default"></slot></span><star v-if="!hideStar" class="ml-1" />
  </div>
</template>

<script>
export default {
  props: {
    number: String,
    hideStar: { type: Boolean, default: false }
  }
}
</script>

<style>
</style>