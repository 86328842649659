<template>
  <div>
    <StepHeadline hideCaption>Résumé de l'inscription</StepHeadline>
    <div class="formulaire-inner">
      <resume-part
        title="Nom"
        :description="`${formulaire.informationsPersonnelles.prenom} ${formulaire.informationsPersonnelles.nom}`"
      ></resume-part>
      <template v-if="formulaire.coordonnees.adresse1.typeAdresse == 'pro'">
        <resume-part title="Nom de l'organisme" :description="`${formulaire.coordonnees.adresse1.nomOrganisme}`"></resume-part>
      </template>
      <template v-if="formulaire.coordonnees.ajouterUneAutreAdresse && formulaire.coordonnees.adresse2.typeAdresse == 'pro'">
        <resume-part title="Nom de l'organisme" :description="`${formulaire.coordonnees.adresse2.nomOrganisme}`"></resume-part>
      </template>
      <asdeq-separator />
      <resume-part v-if="estComplete" title="Inscription à l'ensemble du congrès" description="Oui"></resume-part>
      <resume-part
        v-if="formulaire.inscription.presence"
        title="Type d'inscription"
        :description="getPresenceFr(formulaire.inscription.presence)"
      ></resume-part>
      <div v-if="!estComplete">
        <h4>Activités retenues:</h4>
        <ul>
          <li v-for="a in activitesSelectionnes" :key="a.produitId" class="mb-0">
            {{ a.nomActivite }}
            <span v-if="formulaire.inscription.tarifsSelectionnes.some((t) => t && t.startsWith(a.produitId + '|'))">
              &mdash; {{ formulaire.inscription.tarifsSelectionnes.find((t) => t && t.startsWith(a.produitId + "|")).split("|")[2] }}</span
            >
          </li>
        </ul>
      </div>
      <asdeq-separator />
      <h4 class="mb-4">Total des frais d'inscription ($)</h4>
      <Panier :amount="montantTotalActivites"></Panier>
      <v-checkbox label="Je désire avoir un reçu" v-model="formulaire.informationsProfessionnelles.desireAvoirRecu"></v-checkbox>
    </div>
  </div>
</template>

<script>
import StepHeadline from "@/components/StepHeadline"
import Panier from "@/components/Panier"
import InscriptionActivites from "@/mixins/InscriptionActivites"
export default {
  mixins: [InscriptionActivites],
  props: {
    formulaire: { type: Object }
  },
  components: {
    StepHeadline,
    Panier
  }
}
</script>

<style>
</style>