import FormulaireLayout from "@/layouts/Formulaire.vue"
import { mapMutations } from "vuex"
import ServiceCatalogues from "@/plugins/services/catalogues"

export default {
  props: {
    step: {
      type: String
    }
  },

  data() {
    return {
      loading: false,
      formulaireNonValide: false,
      currentStepIndex: 0,
      hasConfirmed: []
    }
  },

  components: {
    FormulaireLayout
  },

  created() {
    this.createdMethod()
  },

  computed: {

    currentStep() {
      return this.findStep(this.step)
    },

    canSubmit() {
      return this.steps.every((s) => s.state == "success")
    },

    isFirstStep() {
      return this.currentStepIndex === 0
    },

    isLastStep() {
      return this.currentStepIndex === this.steps.length - 1
    }
  },

  methods: {
    ...mapMutations(["SET_CATALOGUES"]),

    async createdMethod() {
      this.firstStep();
      await this.loadCatalogues()
      this.hasConfirmed = this.steps.map(x => { return { path: x.path, hasConfirmed: false } })
    },

    async loadCatalogues() {
      this.loading = true
      for (let i = 0; i < this.cataloguesToLoad.length; i++) {
        let key = this.cataloguesToLoad[i]
        try {
          this.loadCatalogue(key, await ServiceCatalogues[key]());
        } catch {
          //this.$router.push({ name: "Erreur" })
        }
      }
      this.loading = false
    },

    loadCatalogue(id, res) {
      let values = res.data.listeCatalogValues
      if (res.data.success && values && values.length > 0) {
        let valuesArray = values
        valuesArray.sort((a, b) => Intl.Collator().compare(a.libelleFR, b.libelleFR))
        this.SET_CATALOGUES({ id, values: valuesArray })
      }
    },

    firstStep() {
      this.goToStep(0, false)
    },

    next() {
      this.goToStep(this.currentStepIndex + 1)
    },

    back() {
      this.goToStep(this.currentStepIndex - 1, false)
    },

    goToStep(index, withValidation = true) {
      let stepIsValid = !withValidation || this.validateStep(this.currentStep)

      if (stepIsValid) {
        this.currentStepIndex = index
        this.$vuetify.goTo(0)
        this.formulaireNonValide = false
        this.$router.push({
          path: this.url + this.steps[index].path,
          query: { ...this.$route.query }
        })
      }

    },

    findStep(step) {
      return this.steps.find((s) => s.path == step)
    },

    isStepConfirmed(path) {
      let found = this.hasConfirmed.find(s => s.path == path)
      return found ? found.hasConfirmed : false
    },

    stepConfirmed(step) {
      this.hasConfirmed.find((s) => s.path == step.path).hasConfirmed = true
    },

    validate() {
      let ok = true
      for (let i = 0; i < this.steps.length; i++) {
        ok = this.validateStep(this.steps[i]) && ok
      }
      return ok
    },

    validateStep(step) {
      let stepRefs = this.$refs[step.path]
      if (stepRefs) {
        let stepRef = undefined
        if (stepRefs.length == 1) {
          stepRef = stepRefs[0]
        } else {
          stepRef = stepRefs
        }
        let stepMainForm = stepRef.$refs.mainForm
        if (stepMainForm) {
          this.stepConfirmed(step)
          let validation = stepMainForm.validate()
          step.valid = validation
          return validation
        }
      }
      return true
    },

    setState(step, state) {
      if (this.isStepConfirmed(step)) {
        this.findStep(step).valid = state
      }
    },

    submit() {
      this.formulaireNonValide = false
      this.hasConfirmedOnce = true
      if (this.validate()) {
        //this.loading = true
        this.submitCustom()
      } else {
        this.formulaireNonValide = true
      }
    }
  },
  mounted() {
    if (this.sas) {
      this.findStep("introduction").state = "success"
    }
  }
}
