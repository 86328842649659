<template>
  <div class="formulaire">
    <div id="watermark"></div>
    <v-main>
      <v-sheet class="py-4 py-md-8 py-lg-12 px-2 px-md-6 px-lg-12 mx-auto transparent" max-width="1200">
        <div class="mb-3 mb-md-8" v-if="steps && steps.length > 1">
          <Steps :steps="steps" :activeStepId="currentStepIndex" @goToStep="goToStep" />
        </div>
        <div class="mb-3 mb-md-8">
          <h1 class="text-center display-1 primary--text font-weight-bold"><slot name="title"></slot></h1>
          <h2 class="text-center subtitle-1"><slot name="subtitle"></slot></h2>
        </div>

        <v-sheet max-width="800" class="mx-auto formulaireStep pb-12">
          <template v-if="loading">
            <asdeq-loading />
          </template>
          <template v-else>
            <slot name="default"></slot>
          </template>
        </v-sheet>
      </v-sheet>
    </v-main>
  </div>
</template>

<script>
import Steps from "@/components/Steps"
export default {
  props: {
    steps: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    },
    currentStepIndex: {
      type: Number
    }
  },
  components: { Steps },
  methods: {
    goToStep(index) {
      this.$emit("goToStep", index)
    }
  }
}
</script>

<style lang="scss">
@import "@/sass/_colors.scss";

.formulaire {
  #watermark {
    position: fixed;
    height: 600px;
    width: 120px;
    background-color: $baseBlue;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  .v-sheet.formulaireStep {
    border: 1px solid $baseBlue;
    border-radius: 15px;
    padding: 70px 20px 0 20px;
  }

  .formulaire-inner {
    padding: 0 0px;
    @media screen and (min-width: 600px) {
      padding: 0 50px;
    }
    @media screen and (min-width: 992px) {
      padding: 0 150px;
    }
  }
}
</style>
