<template>
  <div>
    <v-sheet max-width="350" class="pl-6 pr-12">
      <v-radio-group v-model="invite.tarif" :rules="required" class="mb-3" dense>
        <v-radio v-for="tarif in tarifsUniqueActivite" :key="tarif.tarifId" :value="tarif">
          <template v-slot:label
            ><span>{{ tarif.typeTarif }}</span
            ><v-spacer></v-spacer><span>{{ tarif.montantAvantTaxe | money }}</span></template
          >
        </v-radio>
      </v-radio-group>

      <template v-if="readOnly">
        <ResumePart title="Prénom" :description="invite.prenom" />
        <ResumePart title="Nom" :description="invite.nom" />
        <ResumePart title="Courriel" :description="invite.courriel" />
      </template>
      <template v-else>
        <v-text-field outlined v-model="invite.prenom" :rules="required" dense>
          <template v-slot:label>Prénom<star /></template>
        </v-text-field>
        <v-text-field outlined v-model="invite.nom" :rules="required" dense>
          <template v-slot:label>Nom<star /></template>
        </v-text-field>
        <v-text-field outlined v-model="invite.courriel" :rules="requiredEmail" dense>
          <template v-slot:label>Courriel<star /></template>
        </v-text-field>
      </template>
      <div v-if="enPresentielle">
        <p>Restrictions alimentaires?</p>
        <v-radio-group row v-model="invite.hasRestrictionsAlimentaires" :rules="required" @change="invite.restrictionsAlimentaires = []">
          <v-radio value="oui" label="Oui">Oui</v-radio>
          <v-radio value="non" label="Non">Non</v-radio>
        </v-radio-group>
        <v-select
          v-if="invite.hasRestrictionsAlimentaires === 'oui'"
          outlined
          v-model="invite.restrictionsAlimentaires"
          multiple
          :items="catalogues.restrictionsAlimentaires"
          item-text="libelleFR"
          item-value="id"
          :rules="multipleSelectRequired"
          label="Veuillez sélectionner un choix"
        >
        </v-select>
        <v-text-field
          v-if="
            invite.hasRestrictionsAlimentaires === 'oui' &&
            invite.restrictionsAlimentaires &&
            invite.restrictionsAlimentaires.some((r) => r === 1110838)
          "
          v-model="invite.autresRestrictionsAlimentaires"
          outlined
          :rules="required"
          label="Spécifiez"
        ></v-text-field>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import Rules from "@/mixins/Rules"
import ResumePart from "@/components/Common/ResumePart"
import { mapGetters } from "vuex"
export default {
  props: {
    invite: Object,
    readOnly: {
      type: Boolean,
      default: false
    },
    enPresentielle: Boolean
  },
  computed: {
    ...mapGetters(["tarifsUniqueActivite", "catalogues"])
  },
  mixins: [Rules],
  components: {
    ResumePart
  }
}
</script>

<style>
</style>