
export default {
  state: () => ({
    statutContact: {}
  }),
  getters: {
    statutContact: (state) => {
      return state.statutContact
    },
    service: (state) => {
      if (state.statutContact.statut == 1110658) {
        return "Renouvellement d’adhésion"
      } else {
        return "Nouvelle adhésion"
      }
    },
    serviceShort: (state) => {
      if (state.statutContact.statut == 1110658) {
        return "renouvellement"
      } else {
        return "adhésion"
      }
    },
    estMembre: (state) => {
      return state.statutContact.statut == 1110658
    },
    statutContactCode: (state) => {
      if (state.statutContact.statut == 1110658) {
        return "M"
      } else if (state.statutContact.statut == 1110659) {
        return "NM"
      } else if (state.statutContact.statut == 1110663) {
        return "AM"
      }
      return "NM"
    }
  },
  mutations: {
    SET_STATUT_CONTACT(state, statutContact) {
      state.statutContact = statutContact
    }
  }
}