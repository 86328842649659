export default {
  data() {
    return {
      valid: false,
    }
  },
  watch: {
    valid() {
      this.$emit("validationChanged", this.valid)
    }
  }
}
