import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/es5/locale/fr';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#0076AC",
        secondary: "#1D1D1B",
        accent: "#009EE2",
        error: "#FF5252",
        info: "#2196F3",
        success: "#93d500",
        warning: "#FFC107",
      },
      dark: {
        primary: "#1D1D1B",
        secondary: "#F8F8F8",
      },
    },
  },
  lang: {
    locales: { fr },
    current: 'fr',
  },
});
