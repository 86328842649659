<template>
  <div class="steps">
    <div
      v-for="step in steps"
      :key="step.id"
      class="step"
      :class="{ active: step.id == activeStepId, beforeActive: step.id < activeStepId, afterActive: step.id > activeStepId, invalid: !step.valid }"
    >
      <div class="left-line"></div>
      <div class="innerstep">
        {{ step.id + 1 }}
        <div class="innerstep--hover">{{ step.name }}</div>
      </div>

      <div class="right-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: { type: Array },
    activeStepId: { type: Number }
  },
  methods: {
    goToStep(index) {
      this.$emit("goToStep", index)
    }
  }
}
</script>

<style lang="scss">
@import "@/sass/_colors.scss";
$stepSize: 50px;
$solidLine: 2px solid $accent1;
$dashedLine: 2px dashed $accent1;

.steps {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-around;
  margin: 0 auto;
  .step {
    width: 100%;
    display: flex;
    position: relative;
    &.invalid {
      > .innerstep:after {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        width: 25px;
        height: 25px;
        font-size: 17px;
        content: "!";
        color: var(--v-error-base);
        background: white;
        border: 2px solid var(--v-error-base);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    > .innerstep {
      background-color: white;
      color: $baseBlue;
      border: 2px solid $baseBlue;
      border-radius: 5px;
      width: $stepSize;
      height: $stepSize;
      font-size: $stepSize/2;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &:hover {
        .innerstep--hover {
          display: block;
        }
      }
      & .innerstep--hover {
        display: none;
        position: absolute;
        top: 115%;
        font-size: 12px;
        background-color: $baseBlack;
        color: $baseWhite;
        opacity: 70%;
        padding: 0.3em 0.6em;
        border-radius: 4px;
        white-space: nowrap;
        box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.3);
        transition: transform 0.04s ease-in-out;
      }
    }
    > .left-line,
    > .right-line {
      width: calc(50% - #{$stepSize}/ 2);
      margin-top: $stepSize/2;
    }
    &.beforeActive {
      > .left-line,
      > .right-line {
        border-top: $solidLine;
      }
    }
    &.active {
      > .left-line {
        border-top: $solidLine;
      }
      > .right-line {
        border-top: $dashedLine;
      }
    }
    &.afterActive {
      > .left-line,
      > .right-line {
        border-top: $dashedLine;
      }
    }
    &.beforeActive,
    &.active {
      > .innerstep {
        color: white;
        background-color: $accent1;
        border: none;
      }
    }
  }
}
</style>