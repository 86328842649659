import Vue from 'vue'

import Loading from "@/components/Common/Loading.vue";
import Separator from "@/components/Common/Separator.vue";
import ResumePart from "@/components/Common/ResumePart.vue";
import MandatoryStar from "@/components/MandatoryStar.vue";
Vue.component("asdeq-loading", Loading)
Vue.component("asdeq-separator", Separator)
Vue.component("resume-part", ResumePart)
Vue.component("star", MandatoryStar)
